import { useLocation, Navigate, Outlet } from "react-router-dom";
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const GuestGuard = () => {

  const { user } = useAuth();
  const location = useLocation();
  //console.log('GuestGuard', user, location)
  return user ? <Navigate to="/dashboard" state={{ from: location }} replace /> : <Outlet />
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
