import axios from 'axios';
import { nillioAPIConfig } from '../config';
import { auth } from "../lib/firebase";

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: nillioAPIConfig.baseURL
});

client.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${auth.currentUser.accessToken}`
  return config;
})

client.interceptors.response.use((response) => {
  return response;
},
  (error) => {

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {

      originalRequest._retry = true;
      return auth.currentUser?.getIdToken(false).then((token) => {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return axios(originalRequest);
      });
    }
    return Promise.reject(error);
  },
);

/**
 * Request Wrapper with default success/error actions
 */
const BackendAPI = (options) => {

  const onSuccess = (response) => {
    console.debug('Request Successful', response)
    return response.data;
  }

  const onError = (error) => {
    console.error('Request Failed', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status)
      console.error('Headers:', error.response.headers)
      console.error('Data:', error.response.data)
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message)
    }

    return Promise.reject(error.response || error.message)
  }

  return client(options)
    .then(onSuccess)
    .catch(onError)
}

export default BackendAPI;