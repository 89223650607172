import { getDatabase, onValue, ref, set } from "firebase/database";

export const searchCarriers = async (setCarriers) => {
  try {
    const db = getDatabase();
    const starCountRef = await ref(db, 'portal/carriers');

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setCarriers(data);
    });

  } catch (e) {
    console.log("Error getting Firebase Carriers:", e);
  }
};

export const updateCarriers = async (data) => {
  const db = getDatabase();
  const portalRef = await ref(db, 'portal/carriers');

  return await set(portalRef, data);
};

export const searchSettings = async (setSettings) => {
  try {
    const db = getDatabase();
    const portalRef = await ref(db, 'portal/settings');

    onValue(portalRef, (snapshot) => {
      const data = snapshot.val();
      setSettings(data);
    });

  } catch (e) {
    console.log("Error getting Firebase Settings:", e);
  }
};

export const updateSetting = async (data) => {
  const db = getDatabase();
  const portalRef = await ref(db, 'portal/settings');

  return await set(portalRef, data);
};

export const searchCatalogIntegrations = async (setSettings, enabledOnly = false) => {
  try {
    const db = getDatabase();
    const portalRef = await ref(db, 'portal/catalog_integration_types');

    onValue(portalRef, (snapshot) => {
      const data = snapshot.val();
      if (enabledOnly === true) {
        const settings = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].enabled === true) {
            settings.push(data[i]);
          }
        }
        setSettings(settings);
      } else {
        setSettings(data);
      }
    });

  } catch (e) {
    console.log("Error getting Firebase Catalog Integration Settings:", e);
  }
};

export const updateCatalogIntegration = async (data) => {
  const db = getDatabase();
  const portalRef = await ref(db, 'portal/catalog_integration_types');

  return await set(portalRef, data);
};

export const searchMobile = async (setSettings) => {
  try {
    const db = getDatabase();
    const portalRef = await ref(db, 'mobile');

    onValue(portalRef, (snapshot) => {
      const data = snapshot.val();
      setSettings({
        ios: data.ios ? data.ios : {},
        android: data.android ? data.android : {},
      });
    });

  } catch (e) {
    console.log("Error getting Firebase Mobile Settings:", e);
  }
};

export const updateMobile = async (platform, data) => {
  const db = getDatabase();
  const portalRef = await ref(db, 'mobile/' + platform);

  return await set(portalRef, data);
};

export const searchSavedFields = async (userId, section, type, setSavedFields) => {
  const path = type
    ? 'portal/saved_fields/' + section + '/' + type + '/' + userId
    : 'portal/saved_fields/' + section + '/' + userId;

  try {
    const db = getDatabase();
    const portalRef = await ref(db, path);

    onValue(portalRef, (snapshot) => {
      const data = snapshot.val();
      setSavedFields(data);
    });

  } catch (e) {
    console.log("Error getting Firebase Saved Fields:", e);
  }
};

export const updateSavedFields = async (userId, section, type, data) => {
  const path = type
    ? 'portal/saved_fields/' + section + '/' + type + '/' + userId
    : 'portal/saved_fields/' + section + '/' + userId;

  const db = getDatabase();
  const portalRef = await ref(db, path);

  return await set(portalRef, data);
};

export const fetchPortalData = async (setPortalData) => {
  try {
    const db = getDatabase();
    const starCountRef = await ref(db, 'portal');

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setPortalData(data);
    });

  } catch (e) {
    console.log("Error getting Firebase Portal Data:", e);
  }
};
