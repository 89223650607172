import React, { createContext, useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import Spinner from "../views/spinner/Spinner";
import { fetchPortalData } from "../services/FirebaseService";
import Label from "../components/forms/custom-elements/Label";

const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [isInitialized, setInitialized] = useState(false);
  const [portalData, setPortalData] = useState(null);

  const [attributesMatrix, setAttributesMatrix] = useState(null);
  const [labels, setLabels] = useState(null);
  const [options, setOptions] = useState(null);
  const [settings, setSettings] = useState(null);
  const [systemComments, setSystemComments] = useState(null);

  const getPortalData = useCallback(async () => {
    if (isEmpty(portalData)) {
      await fetchPortalData(setPortalData);
    }
  }, [portalData]);

  useEffect(() => {
    if (!isInitialized) {
      getPortalData().then(() => {
        setInitialized(true);
      });
    }
  }, [getPortalData, isInitialized]);

  useEffect(() => {
    if (portalData) {
      setAttributesMatrix(portalData.attributes_matrix);
      setLabels(portalData.labels);
      setOptions(portalData.options);
      setSettings(portalData.settings);
      setSystemComments(portalData.system_comments);
    }
  }, [portalData]);

  if (!isInitialized) {
    return <Spinner />
  }

  const getLabel = (mapping, value, opaque = true, cursor = 'default', size = 'small') => {
    if (mapping && mapping[value]) {
      const { type, text } = mapping[value];

      return (
        <Label type={type} text={text} opaque={opaque} cursor={cursor} size={size} />
      );
    }
  };

  const productActionsByType = (type) => {
    if (labels && type === 'parent') {
      return labels.productActionsByTypeParent;
    } else if (labels) {
      return labels.productActionsByTypeVariation;
    }
  };

  const getShippingStatusLabel = (value) => {
    if (labels) {
      return getLabel(labels.shippingStatusMapping, value);
    }
  };

  const getTrackerStatusLabel = (value) => {
    if (labels) {
      return getLabel(labels.trackerStatusMapping, value);
    }
  };

  const getTrackerStatusColor = (value) => {
    const mapping = labels && labels.trackerStatusMapping ? labels.trackerStatusMapping : {};
    const { type } = mapping[value];

    return type;
  };

  const getStatusLabel = (value, cursor = 'default', size = 'small') => {
    if (labels) {
      return getLabel(labels.statusMapping, value, true, cursor, size);
    }
  };

  const getStateLabel = (value) => {
    if (labels) {
      return getLabel(labels.stateMapping, value);
    }
  };

  const getVisibilityLabel = (value) => {
    if (labels) {
      return getLabel(labels.visibilityMapping, value);
    }
  };

  const getConditionLabel = (value) => {
    if (labels) {
      return getLabel(labels.conditionMapping, value);
    }
  };

  const getPimStatusLabel = (value) => {
    if (labels) {
      return getLabel(labels.pimStatusMapping, value);
    }
  };

  const getUserTypeLabel = (value) => {
    if (labels) {
      return getLabel(labels.userTypeMapping, value);
    }
  };

  const getActionLabel = (value) => {
    if (labels) {
      return getLabel(labels.actionMapping, value);
    }
  };

  const getPayeeTypeLabel = (value) => {
    if (labels) {
      return getLabel(labels.payeeTypeMapping, value);
    }
  };

  const getMethodLabel = (value) => {
    if (labels) {
      return getLabel(labels.methodsMapping, value.toLowerCase(), false);
    }
  };

  const getTierLabel = (value, cursor = 'default') => {
    if (!value) {
      value = 'None';
    }

    if (labels) {
      return getLabel(labels.tierMapping, value, false, cursor);
    }
  };

  const getMarkupTypeLabel = (value, cursor = 'default') => {
    if (labels) {
      return getLabel(labels.markupTypeMapping, value, true, cursor);
    }
  };

  const getRateTypeLabel = (value, cursor = 'default') => {
    if (labels) {
      return getLabel(labels.rateTypeMapping, value, true, cursor);
    }
  };

  const getRateUnitSystemLabel = (value, cursor = 'default') => {
    if (labels) {
      return getLabel(labels.rateUnitSystemMapping, value, true, cursor);
    }
  };

  const getStockLabel = (value) => {
    if (labels) {
      return getLabel(labels.stockMapping, value);
    }
  };

  const getMenuItemTypeLabel = (value, cursor = 'default', size = 'small') => {
    if (labels) {
      return getLabel(labels.menuItemTypeMapping, value, true, cursor, size);
    }
  };

  const typeLabel = (value) => {
    if (labels && value !== 'None') {
      return getLabel(labels.merchantTypeMapping, value);
    } else {
      return (
        <Label type="primary" text="System or User" />
      );
    }
  };

  const getDiscountMethodLabel = (value) => {
    if (labels) {
      return getLabel(labels.discountMethodsMapping, value.toLowerCase(), true);
    }
  };

  const getDiscountTypeLabel = (value) => {
    if (labels) {
      return getLabel(labels.discountTypeMapping, value.toLowerCase(), true);
    }
  };

  return (
    <ContentContext.Provider
      value={{
        attributesMatrix,
        labels,
        options,
        settings,
        systemComments,

        getLabel,
        productActionsByType,
        getShippingStatusLabel,
        getTrackerStatusLabel,
        getTrackerStatusColor,
        getStatusLabel,
        getStateLabel,
        getVisibilityLabel,
        getConditionLabel,
        getPimStatusLabel,
        getUserTypeLabel,
        getActionLabel,
        getPayeeTypeLabel,
        getMethodLabel,
        getTierLabel,
        getMarkupTypeLabel,
        getRateTypeLabel,
        getRateUnitSystemLabel,
        getStockLabel,
        getMenuItemTypeLabel,
        typeLabel,
        getDiscountMethodLabel,
        getDiscountTypeLabel,
      }}>
      {children}
    </ContentContext.Provider>
  )
}

export default ContentContext;
