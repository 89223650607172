import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import AuthGuard from '../components/auth/AuthGuard';
import GuestGuard from '../components/auth/GuestGuard';
//import useAuth from '../hooks/useAuth';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../views/authentication/NewPassword')));
const EmailVerification = Loadable(lazy(() => import('../views/authentication/EmailVerification')));
const VerifyEmail = Loadable(lazy(() => import('../views/authentication/VerifyEmail')));

const PendingRegistration = Loadable(lazy(() => import('../views/pending/PendingRegistration')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));

const Tenants = Loadable(lazy(() => import('../views/admin/tenants/Tenants')));
const TenantDetails = Loadable(lazy(() => import('../views/tenant/details/TenantDetails')));
const Catalog = Loadable(lazy(() => import('../views/catalog/Catalog')));
const ProductDetails = Loadable(lazy(() => import('../views/catalog/details/ProductDetails')));
const Payouts = Loadable(lazy(() => import('../views/payouts/Payouts')));
const PayoutDetails = Loadable(lazy(() => import('../views/payouts/details/PayoutDetails')));
const Discounts = Loadable(lazy(() => import('../views/discounts/Discounts')));
const DiscountDetails = Loadable(lazy(() => import('../views/discounts/DiscountDetails')));
const Shipments = Loadable(lazy(() => import('../views/merchants/shipments/Shipments')));
const ShipmentDetails = Loadable(lazy(() => import('../views/merchants/shipments/details/ShipmentDetails')));
const Repricer = Loadable(lazy(() => import('../views/repricer/Repricer')));
const RepricerLog = Loadable(lazy(() => import('../views/repricer/log/RepricerLog')));
//const Merchants = Loadable(lazy(() => import('../views/merchants/list/Merchants')));
const MerchantAccountSettings = Loadable(lazy(() => import('../views/merchants/account/MerchantAccountSettings')));
const MerchantRanking = Loadable(lazy(() => import('../views/merchants/ranking/MerchantRanking')));
const BrandPermissions = Loadable(lazy(() => import('../views/merchants/permissions/BrandPermissions')));
const MerchantOrders = Loadable(lazy(() => import('../views/merchants/orders/MerchantOrders')));
const OrderDetails = Loadable(lazy(() => import('../views/merchants/orders/details/OrderDetails')));
const MerchantIntegration = Loadable(lazy(() => import('../views/merchants/integration/MerchantIntegration')));
const InfluencerTiers = Loadable(lazy(() => import('../views/influencers/tiers/InfluencerTiers')));
const Influencers = Loadable(lazy(() => import('../views/influencers/Influencers')));
const PendingInfluencers = Loadable(lazy(() => import('../views/merchants/pending-influencers/PendingInfluencers')));
const InfluencerDetails = Loadable(lazy(() => import('../views/influencers/details/InfluencerDetails')));

const Spaces = Loadable(lazy(() => import('../views/spaces/Spaces')));
const SpaceDetails = Loadable(lazy(() => import('../views/spaces/details/SpaceDetails')));

const SpaceInfluencerSubscriptions = Loadable(lazy(() => import('../views/spaces/space-influencer-subscriptions/SpaceInfluencerSubscriptions')));
const SpaceInfluencerSubscriptionsCreate = Loadable(lazy(() => import('../views/spaces/space-influencer-subscriptions/SpaceInfluencerSubscriptionsCreate')));
const SpaceInfluencerSubscriptionsList = Loadable(lazy(() => import('../views/spaces/space-influencer-subscriptions/list/SpaceInfluencerSubscriptions')));
const SpaceInfluencerSubscriptionsDetails = Loadable(lazy(() => import('../views/spaces/space-influencer-subscriptions/details/SpaceInfluencerSubscriptionDetails')));

const SpaceMerchantSubscriptions = Loadable(lazy(() => import('../views/spaces/space-merchant-subscriptions/SpaceMerchantSubscriptions')));
const SpaceMerchantSubscriptionsCreate = Loadable(lazy(() => import('../views/spaces/space-merchant-subscriptions/SpaceMerchantSubscriptionsCreate')));
const SpaceMerchantSubscriptionsList = Loadable(lazy(() => import('../views/spaces/space-merchant-subscriptions/list/SpaceMerchantSubscriptions')));
const SpaceMerchantSubscriptionsDetails = Loadable(lazy(() => import('../views/spaces/space-merchant-subscriptions/details/SpaceMerchantSubscriptionDetails')));

const Navigation = Loadable(lazy(() => import('../views/store/navigation/Navigation')));
const Settings = Loadable(lazy(() => import('../views/store/settings/Settings')));

const Users = Loadable(lazy(() => import('../views/users/Users')));
const UserDetails = Loadable(lazy(() => import('../views/users/details/UserDetails')));
const UserProfile = Loadable(lazy(() => import('../views/user-profile/UserProfile')));
const AccessControl = Loadable(lazy(() => import('../views/admin/access-control/AccessControl')));
const Categories = Loadable(lazy(() => import('../views/categories/Categories')));
const Events = Loadable(lazy(() => import('../views/events/Events')));
const Preview = Loadable(lazy(() => import('../views/preview/Preview')));
const Products = Loadable(lazy(() => import('../views/catalog/products/Products')));
const ShippingSettings = Loadable(lazy(() => import('../views/merchants/account/settings/shipping/ShippingSettings')));
const GeneralSettings = Loadable(lazy(() => import('../views/admin/settings/general/General')));
const MobileSettings = Loadable(lazy(() => import('../views/admin/settings/mobile/Mobile')));
const Empty = Loadable(lazy(() => import('../views/empty/Empty')));

const MerchantsSubscriptions = Loadable(lazy(() => import('../views/tenant/merchants-subscriptions/MerchantsSubscriptions')));
const InfluencerMerchantSubscriptions = Loadable(lazy(() => import('../views/influencers/influencer-merchant-subscriptions/InfluencerMerchantSubscriptions')));
const Subscriptions = Loadable(lazy(() => import('../views/merchants/tenant-subscriptions/TenantSubscriptions')));
const Customers = Loadable(lazy(() => import('../views/tenant/customers/Customers')));
const CustomerDetails = Loadable(lazy(() => import('../views/tenant/customers/details/CustomerDetails')));

const AccountWrapper = Loadable(lazy(() => import('../views/wrappers/AccountWrapper')));
const MerchantsWrapper = Loadable(lazy(() => import('../views/wrappers/MerchantsWrapper')));
const SpacesWrapper = Loadable(lazy(() => import('../views/wrappers/SpacesWrapper')));
const SubscriptionsWrapper = Loadable(lazy(() => import('../views/wrappers/SubscriptionsWrapper')));
const SubscriptionDetailsWrapper = Loadable(lazy(() => import('../views/wrappers/SubscriptionDetailsWrapper')));
const CreateSubscriptionWrapper = Loadable(lazy(() => import('../views/wrappers/CreateSubscriptionWrapper')));

/* ****Routes***** */

const Router = [
  {
    element: <AuthGuard />,
    children: [
      {
        path: '/',
        element: <FullLayout />,
        children: [
          { path: '/', element: <Navigate to="/dashboard" /> },
          { path: 'dashboard', index: true, element: <Dashboard /> },

          // Admin routes
          { path: 'users', index: true, element: <Users /> },
          { path: 'users/:userId', index: true, element: <UserDetails /> },
          { path: 'access-control', index: true, element: <AccessControl /> },

          { path: 'repricer', index: true, element: <Repricer /> },
          { path: 'repricer/log', index: true, element: <RepricerLog /> },

          { path: 'categories', index: true, element: <Categories /> },
          { path: 'events/promotions', index: true, element: <Events type="promotions" /> },
          { path: 'events/search', index: true, element: <Events type="search" /> },
          { path: 'preview', index: true, element: <Preview /> },
          { path: 'shipping-settings', index: true, element: <ShippingSettings /> },
          { path: 'general-settings', index: true, element: <GeneralSettings /> },
          { path: 'mobile-settings', index: true, element: <MobileSettings /> },

          { path: 'merchants/:merchantId', index: true, element: <MerchantAccountSettings /> },
          { path: 'merchant-ranking', index: true, element: <MerchantRanking /> },
          { path: 'merchant-integration', index: true, element: <MerchantIntegration /> },
          { path: 'brand-permissions', index: true, element: <BrandPermissions /> },

          { path: 'tenants', index: true, element: <Tenants /> },
          { path: 'tenants/:tenantId', index: true, element: <TenantDetails /> },

          { path: 'tenant-merchant-subscriptions/tenant/:id', index: true, element: <MerchantsSubscriptions /> },
          { path: 'tenant-merchant-subscriptions/merchant/:id', index: true, element: <Subscriptions /> },

          { path: 'influencer-merchant-subscriptions/influencer/:id', index: true, element: <InfluencerMerchantSubscriptions /> },
          { path: 'influencer-merchant-subscriptions/merchant/:id', index: true, element: <InfluencerMerchantSubscriptions /> },

          { path: 'payouts', index: true, element: <Payouts /> },
          { path: 'payouts/:payoutId', index: true, element: <PayoutDetails /> },

          { path: 'discounts', index: true, element: <Discounts /> },
          { path: 'discounts/:discountId', index: true, element: <DiscountDetails /> },
          { path: 'discounts/new/:type', index: true, element: <DiscountDetails /> },

          // Merchant routes
          { path: 'profile', index: true, element: <UserProfile /> },
          { path: 'account', index: true, element: <AccountWrapper /> },

          { path: 'orders', index: true, element: <MerchantOrders /> },
          { path: 'orders/:orderId', index: true, element: <OrderDetails /> },

          { path: '/alerts/orders_not_shipped', index: true, element: <MerchantOrders alert="orders_not_shipped" /> },
          { path: '/alerts/not_delivered', index: true, element: <MerchantOrders alert="not_delivered" /> },

          { path: 'returns', index: true, element: <Empty title="Returns" /> },
          { path: 'cancellations', index: true, element: <MerchantOrders alert="cancellations" /> },

          { path: 'shipments', index: true, element: <Shipments /> },
          { path: 'shipments/:shipmentId', index: true, element: <ShipmentDetails /> },

          { path: 'influencers-tiers', index: true, element: <InfluencerTiers /> },
          { path: 'influencers', index: true, element: <Influencers /> },
          { path: 'pending-influencers', index: true, element: <PendingInfluencers /> },
          { path: 'influencers/:influencerId', index: true, element: <InfluencerDetails /> },

          { path: 'pim/parent', index: true, element: <Catalog type="parent" /> },
          { path: 'pim/variation', index: true, element: <Catalog type="variation" /> },
          { path: 'pim/merchant', index: true, element: <Catalog type="merchant" /> },
          { path: 'pim/merchant-inventory', index: true, element: <Catalog type="merchant_inventory" /> },

          { path: 'pim/product/:id', index: true, element: <ProductDetails /> },

          // Tenant routes
          { path: 'customers', index: true, element: <Customers /> },
          { path: 'customers/:customerId', index: true, element: <CustomerDetails /> },

          { path: 'products/:merchantId', index: true, element: <Products /> },

          // Subscriptions
          { path: 'merchants', index: true, element: <MerchantsWrapper /> },
          { path: 'spaces', index: true, element: <SpacesWrapper /> },

          { path: 'subscriptions', index: true, element: <SubscriptionsWrapper /> },
          { path: 'subscriptions/:id', index: true, element: <SubscriptionDetailsWrapper /> },
          { path: 'subscriptions/create/:id', index: true, element: <CreateSubscriptionWrapper /> },

          { path: 'tenant-merchant-subscriptions', index: true, element: <SubscriptionsWrapper type="tenant" /> },
          { path: 'influencer-merchant-subscriptions', index: true, element: <SubscriptionsWrapper type="influencer" /> },

          { path: 'tenant-subscriptions', index: true, element: <SubscriptionsWrapper type="tenant" /> },
          { path: 'tenant-subscriptions/:id', index: true, element: <SubscriptionDetailsWrapper type="tenant" /> },

          { path: 'merchant-subscriptions', index: true, element: <SubscriptionsWrapper type="merchant" /> },
          { path: 'merchant-subscriptions/:id', index: true, element: <SubscriptionDetailsWrapper type="merchant" /> },

          { path: 'influencer-subscriptions', index: true, element: <SubscriptionsWrapper type="influencer" /> },
          { path: 'influencer-subscriptions/:id', index: true, element: <SubscriptionDetailsWrapper type="influencer" /> },

          { path: 'space-subscriptions', index: true, element: <SubscriptionsWrapper type="space" /> },
          { path: 'space-subscriptions/:id', index: true, element: <SubscriptionDetailsWrapper type="space" /> },
          { path: 'space-subscriptions/create/:id', index: true, element: <CreateSubscriptionWrapper type="space" /> },

          // Spaces
          { path: 'spaces', index: true, element: <Spaces /> },
          { path: 'spaces/:spaceId', index: true, element: <SpaceDetails /> },
          { path: 'space-influencer-subscriptions', index: true, element: <SpaceInfluencerSubscriptions /> },
          { path: 'space-influencer-subscriptions/:subscriptionId', index: true, element: <SpaceInfluencerSubscriptionsDetails /> },
          { path: 'space-influencer-subscriptions/create', index: true, element: <SpaceInfluencerSubscriptionsCreate /> },
          { path: 'space-influencer-subscriptions/space/:id', index: true, element: <SpaceInfluencerSubscriptionsList type="space" /> },
          { path: 'space-influencer-subscriptions/influencer/:id', index: true, element: <SpaceInfluencerSubscriptionsList type="influencer" /> },

          { path: 'space-merchant-subscriptions', index: true, element: <SpaceMerchantSubscriptions /> },
          { path: 'space-merchant-subscriptions/:subscriptionId', index: true, element: <SpaceMerchantSubscriptionsDetails /> },
          { path: 'space-merchant-subscriptions/create', index: true, element: <SpaceMerchantSubscriptionsCreate /> },
          { path: 'space-merchant-subscriptions/space/:id', index: true, element: <SpaceMerchantSubscriptionsList type="space" /> },
          { path: 'space-merchant-subscriptions/merchant/:id', index: true, element: <SpaceMerchantSubscriptionsList type="merchant" /> },

          // Store
          { path: 'store-navigation', index: true, element: <Navigation /> },
          { path: 'store-navigation/:categoryId', index: true, element: <Navigation /> },
          { path: 'store-settings', index: true, element: <Settings /> },
          { path: 'store-settings/:categoryId', index: true, element: <Settings /> },


          { path: '*', element: <Navigate to="/auth/404" /> },
        ]
      }
    ]
  },
  {
    element: <GuestGuard />,
    children: [
      {
        path: '/auth',
        element: <BlankLayout />,
        children: [
          { path: '404', element: <Error /> },
          { path: 'login', element: <Login /> },
          { path: 'register', element: <Register /> },
          { path: 'reset-password', element: <ResetPassword /> },
          { path: 'new-password', element: <NewPassword /> },
          { path: '*', element: <Navigate to="/auth/404" /> },
        ]
      }
    ]
  },
  {
    path: '/pending',
    element: <BlankLayout />,
    children: [
      { path: '', element: <PendingRegistration /> },
    ]
  },
  {
    path: '/email-verification',
    element: <BlankLayout />,
    children: [
      { path: '', element: <EmailVerification /> },
    ]
  },
  {
    path: '/verify-email',
    element: <BlankLayout />,
    children: [
      { path: '', element: <VerifyEmail /> },
    ]
  },
];

const AppRouter = () => {
  const routing = useRoutes(Router);
  //const { user } = useAuth();
  // TODO: return `routing` according to user type
  return routing;
}

export default AppRouter;
