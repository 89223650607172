import { createContext, useState, useEffect, useCallback } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  onAuthStateChanged,
  EmailAuthProvider,
  sendEmailVerification,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  updateEmail,
} from "firebase/auth";
import { auth } from "../lib/firebase";
import Spinner from "../views/spinner/Spinner";
import { getMe, registerUser, updateUser } from "../services/UsersService";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isInitialized, setInitialized] = useState(false);
  const [isRegistrationInProgress, setRegistrationInProgress] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  // console.log('AuthContext', user, isInitialized)

  const register = (email, password, firstName, lastName, type) => {
    setRegistrationInProgress(true);
    setError('');
    return createUserWithEmailAndPassword(auth, email, password).then(userCredential => {
      registerUser({
        type,
        firstName,
        lastName,
        email,
        providerId: userCredential.user.providerData[0]?.providerId,
        providerUserId: userCredential.user.providerData[0]?.uid
      }).then((res) => {
        if (res.status === 'error') {
          setError(res.error);
        } else {
          fetchUser();
        }
      })
    }).finally(() => {
      setRegistrationInProgress(false);
    });
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const oAuthUserRegister = useCallback((userCredential, type) => {
    const displayName = userCredential.user.displayName.split(' ');
    const firstName = displayName[0];
    const lastName = displayName[1] ? displayName[1] : displayName[0];
    setError('');
    registerUser({
      type,
      firstName,
      lastName,
      email: userCredential.user.email,
      providerId: userCredential.user.providerData[0]?.providerId,
      providerUserId: userCredential.user.providerData[0]?.uid
    }).then((res) => {
      if (res.status === 'error') {
        setError(res.error);
      } else {
        fetchUser();
      }
    });
  }, []);

  const oAuthRegister = async (method, type) => {
    if (type) {
      sessionStorage.setItem('userType', type);
    } else {
      sessionStorage.setItem('userType', 'null');
    }

    await oAuthLogin(method);
  };

  const oAuthLogin = async (method) => {
    if (method === 'facebook') {
      const provider = new FacebookAuthProvider();
      await signInWithRedirect(auth, provider);
    }
    if (method === 'google') {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      await signInWithRedirect(auth, provider);
    }
    if (method === 'apple') {
      const provider = new OAuthProvider('apple.com');
      await signInWithRedirect(auth, provider);
    }
  };

  const oAuthLoginPopup = async (method) => {
    if (method === 'facebook') {
      const provider = new FacebookAuthProvider();
      return await signInWithPopup(auth, provider);
    }
    if (method === 'google') {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      return await signInWithPopup(auth, provider);
    }
    if (method === 'apple') {
      const provider = new OAuthProvider('apple.com');
      return await signInWithPopup(auth, provider);
    }
  };

  const oAuthResult = useCallback(async () => {
    const result = await getRedirectResult(auth);
    if (result) {
      const userResult = await fetchUser();
      if (userResult.status !== 'success') {
        // if User not exists in UMS
        const type = sessionStorage.getItem('userType');
        if (type && type !== 'null' && result.user) {
          // if we register a new User
          oAuthUserRegister(result, type);
          sessionStorage.setItem('userType', 'null');
        } else {
          setError('The user associated with this account is not registered in the system. Please create an account.');
        }
      } else if (userResult.user && userResult.user.deleted === true) {
        setError('The user associated with this account is deleted.');
      } else if (userResult.user && userResult.user.disabled === true) {
        setError('The user associated with this account is disabled.');
      }
    }
  }, [oAuthUserRegister]);

  const logout = () => {
    setError('');
    localStorage.clear();
    return signOut(auth);
  };

  const updateUserProfile = (data) => {
    return updateUser(user._id, data).then(() => {
      fetchUser();
    });
  };

  const updateUserEmail = (email) => {
    const user = auth.currentUser;

    return updateEmail(user, email).then(() => {
      updateUserProfile({ email: email });
    });
  };

  const reauthenticateUserPassword = (password) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    return reauthenticateWithCredential(user, credential);
  };

  const reauthenticateUserSocial = (method) => {
    const user = auth.currentUser;
    if (method === 'facebook') {
      const provider = new FacebookAuthProvider();
      return reauthenticateWithPopup(user, provider);
    }
    if (method === 'google') {
      const provider = new GoogleAuthProvider();
      return reauthenticateWithPopup(user, provider);
    }
    if (method === 'apple') {
      const provider = new OAuthProvider('apple.com');
      return reauthenticateWithPopup(user, provider);
    }
  };

  const updateUserPassword = async (currentPassword, newPassword) => {
    // TODO: Curerntly we allow login the dashboard with email only,
    // therefre we reauthenticate the user with his email and password.
    // When other auth provider will be supported (like Google, Facebook),
    // needs to invoke the relevant reauthentication method using the right credentials.
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    return reauthenticateWithCredential(user, credential)
      .then(() => updatePassword(user, newPassword));
  };

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const resetPassword = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword);
  };

  const sendVerificationEmail = () => {
    return sendEmailVerification(auth.currentUser);
  };

  const fetchUser = async () => {
    setError('');
    try {
      const response = await getMe();
      const { type, _id: userId } = response.user;
      localStorage.setItem('user_type', type);
      localStorage.setItem('user_id', userId);

      //console.log('Auth user', response.user);

      if (response.user && !response.user.deleted && !response.user.disabled) {
        setUser({ ...response.user });
      } else {
        setUser(null);
      }

      return response;
    } catch (error) {
      setUser(null);

      return error;
    } finally {
      setInitialized(true);
    }
  }

  useEffect(() => {
    return onAuthStateChanged(auth, (currentUser) => {
      // console.log('onAuthStateChanged', currentUser);
      if (isRegistrationInProgress) {
        return;
      }

      if (currentUser !== null) {
        const url = new URL(document.location.href);
        const urlPath = url.pathname.toString();

        currentUser.emailVerified = true;
        if (
          urlPath !== '/auth/login' &&
          urlPath !== '/email-verification' &&
          urlPath !== '/verify-email' &&
          currentUser.emailVerified === false
        ) {
          navigate('/email-verification');
        } else {
          fetchUser();
        }
      } else {
        setUser(null);
        setInitialized(true);
      }
    })
    // eslint-disable-next-line
  }, [isRegistrationInProgress, navigate, oAuthResult]);

  useEffect(() => {
    oAuthResult();
  }, [oAuthResult]);

  if (!isInitialized) {
    return <Spinner />
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        error,
        setError,
        register,
        login,
        logout,
        fetchUser,
        updateUserProfile,
        updateUserEmail,
        reauthenticateUserPassword,
        reauthenticateUserSocial,
        updateUserPassword,
        forgotPassword,
        resetPassword,
        sendVerificationEmail,
        oAuthRegister,
        oAuthLogin,
        oAuthLoginPopup,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;
