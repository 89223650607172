import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const Label = ({ type, text, size, opaque, cursor, children, ...rest }) => {

  const typeMap = type || 'primary';
  const colorMapping = {
    primary: {
      color: 'primary.main',
      backgroundColor: 'primary.light',
      opaqueColor: '#fff',
      opaqueBackgroundColor: 'primary.main'
    },
    secondary: {
      color: 'secondary.main',
      backgroundColor: 'secondary.light',
      opaqueColor: '#fff',
      opaqueBackgroundColor: 'secondary.main'
    },
    error: {
      color: 'error.main',
      backgroundColor: 'error.light',
      opaqueColor: '#fff',
      opaqueBackgroundColor: 'error.main'
    },
    warning: {
      color: 'warning.main',
      backgroundColor: 'warning.light',
      opaqueColor: '#fff',
      opaqueBackgroundColor: 'warning.main'
    },
    success: {
      color: 'success.main',
      backgroundColor: 'success.light',
      opaqueColor: '#fff',
      opaqueBackgroundColor: 'success.main'
    },
    default: {
      color: 'grey.A900',
      backgroundColor: 'warning.contrastText',
      opaqueColor: '#fff',
      opaqueBackgroundColor: 'warning.main'
    },
  };

  const style = colorMapping[typeMap] ?? { color: 'primary.main', backgroundColor: 'primary.light' };

  return (
    <Chip
      sx={{
        color: opaque ? style.opaqueColor : style.color,
        cursor: cursor ? cursor : 'default',
        backgroundColor: opaque ? style.opaqueBackgroundColor : style.backgroundColor,
        borderRadius: '6px',
        pl: '3px',
        pr: '3px',
      }}
      size={size ? size : 'small'}
      label={text}
      {...rest}
    >
      {children}
    </Chip>
  );
};

Label.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success']),
  text: PropTypes.string,
  opaque: PropTypes.bool,
  children: PropTypes.node,
};

export default Label;
