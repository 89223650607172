import { useLocation, Navigate, Outlet } from "react-router-dom";
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const AuthGuard = () => {
  const { user } = useAuth();
  const location = useLocation();

  if (user === null) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  } else if ((user.type === 'merchant' || user.type === 'tenant' || user.type === 'influencer' || user.type === 'space') && user.entityId === undefined) {
    return <Navigate to="/pending" state={{ from: location }} replace />;
  }

  return <Outlet />
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
