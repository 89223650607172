export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const nillioAPIConfig = {
  baseURL: process.env.REACT_APP_NILLIO_API_BASE_URL,
};

export const cdmsConfig = {
  CDMSPath: process.env.REACT_APP_CDMS_API_PATH,
  CDMSTokenPORTAL: process.env.REACT_APP_CDMS_API_TOKEN_PORTAL,
  CDMSTokenPIM: process.env.REACT_APP_CDMS_API_TOKEN_PIM,
  CDMSApiPath: process.env.REACT_APP_API_PATH,
};

export const imsConfig = {
  IMSPath: process.env.REACT_APP_IMS_API_PATH,
  IMSTokenPORTAL: process.env.REACT_APP_IMS_API_TOKEN_PORTAL,
  IMSTokenPIM: process.env.REACT_APP_IMS_API_TOKEN_PIM,
};

export const pmacConfig = {
  PMACPath: process.env.REACT_APP_PMAC_API_PATH,
  PMACTokenPIM: process.env.REACT_APP_PMAC_API_TOKEN_PIM,
};

export const websiteConfig = {
  websitePath: process.env.REACT_APP_WEBSITE_PATH,
  zapWebsitePath: process.env.REACT_APP_ZAP_WEBSITE_PATH,
  kspWebsitePath: process.env.REACT_APP_KSP_WEBSITE_PATH,
};

export const currencyConfig = {
  functionPath: process.env.REACT_APP_FUNCTION_PATH,
  currencyRatesPath: process.env.REACT_APP_CURRENCY_RATES,
};

export const yotpoConfig = {
  yotpoPath: process.env.REACT_APP_YOTPO_API_BASE_URL,
  yotpoApiKey: process.env.REACT_APP_YOTPO_APP_KEY,
};
