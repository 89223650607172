import BackendAPI from './BackendAPI';

const USERS = '/users'
const ME = '/users/me'
const REGISTER = '/users/register'
const USERS_SEARCH = '/users/search'

export const registerUser = (data) => {
  return BackendAPI({
    url: REGISTER,
    method: 'post',
    data: data
  })
};

export const getMe = () => {
  return BackendAPI({
    url: ME,
    method: 'get'
  })
};

export const getUser = (userId) => {
  return BackendAPI({
    url: `${USERS}/${userId}`,
    method: 'get'
  })
};

export const updateUser = (userId, data) => {
  return BackendAPI({
    url: `${USERS}/${userId}`,
    method: 'put',
    data: data
  })
};

export const deleteUser = (userId) => {
  return BackendAPI({
    url: `${USERS}/${userId}`,
    method: 'delete'
  })
};

export const searchUsers = (data) => {
  return BackendAPI({
    url: USERS_SEARCH,
    method: 'post',
    data: data
  })
};

export const searchCustomers = (data, tenantCode) => {
  const request = {
    url: USERS_SEARCH,
    method: 'post',
    data: data
  };

  if (tenantCode) {
    request.headers = {'ums-tenant-id': tenantCode};
  }

  return BackendAPI(request);
};

export const getCustomer = (customerId, tenantCode) => {
  const request = {
    url: `${USERS}/${customerId}`,
    headers: {'ums-tenant-id': tenantCode},
    method: 'get'
  };

  if (tenantCode) {
    request.headers = {'ums-tenant-id': tenantCode};
  }

  return BackendAPI(request);
};
